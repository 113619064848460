import React, { useEffect, useState, useRef } from "react";
import { useParams } from 'react-router-dom'
import {Helmet} from "react-helmet";

function Order({}) {
    const { retailerId } = useParams()
    // const hostname = "http://localhost:5000";
    const hostname = "https://heed.herokuapp.com";
    const [order, setOrder] = useState([]);
    const [products, setProducts] = useState(false);
    const [subtotal, setSubtotal] = useState(0);
    const [tax, setTax] = useState(0);
    const [total, setTotal] = useState(0); 
    const [failed, setFailed] = useState(false);
    const [name, setName] = useState("");
    const [message, setMessage] = useState("");

    const [retailer, setRetailer] = useState("");
    const [retailerLogo, setRetailerLogo] = useState("");
    const [backgroundColor, setBackgroundColor] = useState("#ffffff");
    const [textColor, setTextColor] = useState("#323232");
    const [secondaryTextColor, setSecondaryTextColor] = useState("#A5A5A5");
    const [accentColor, setAccentColor] = useState("#5096ff");

    useEffect(() => {
        try {

            fetch( `${hostname}/api/retailer/${retailerId}`, {    headers: { 'Content-Type': 'application/json' }, method: "GET", crossDomain:true })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    setRetailer(response.data[0].retailer);
                    setRetailerLogo(response.data[0].logo);
                    if (response.data[0].settings && response.data[0].settings.branding && response.data[0].settings.branding.backgroundColor) {
                        setBackgroundColor(response.data[0].settings.branding.backgroundColor);
                        setTextColor(response.data[0].settings.branding.textColor);
                        setSecondaryTextColor(response.data[0].settings.branding.secondaryTextColor);
                        setAccentColor(response.data[0].settings.branding.accentColor);
                      }
                }
            })
            .catch((err) => {console.log(err)}); 

            // Pull order status

            var orderReceipt = window.location.href.split("/order/")[1];
            // Fetch request
            fetch( `${hostname}/api/orders/${retailerId}/order/${orderReceipt}`, {    headers: { 'Content-Type': 'application/json' }, method: "GET", crossDomain:true })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    // Set Products
                    if (response.data.length > 0) {
                        setOrder(response.data);
                    }
                } else {
                    window.location.href = `/${retailerId}`;
                }
            })
            .catch((err) => {
                window.location.href = `/${retailerId}`;
            }); 
        }
        catch (e) {
            console.log(e);
            window.location.href = `/${retailerId}`;
        }
        
    }, []);

    useEffect(() => {
        if (order.length > 0) {
            console.log(order[0]);
            let status = order[0].status.orderStatus;
            // Failed order status
            if (status == "Cancelled" || status == "TransientProcessingFailure" || status == "NonTransientProcessingFailure") {
                setFailed(true);
            }
            setName(`${order[0].order.firstName} ${order[0].order.lastName}`);
            setMessage(order[0].status.message);
            let cartStorage = order[0].order.products;

            // var cartStorage = JSON.parse(localStorage.getItem('heed-cart'));
            // if (cartStorage) {
            if (cartStorage.length <= 0) {
                window.location.href = `/${retailerId}`;
            }
            // Calculate totals
            var priceAmount = 0;
            
            setProducts(cartStorage);

            for (var i = 0; i < cartStorage.length; i++) {
                var product = cartStorage[i];
                var price = product.Prices[0] * product.ATCQUANTITY;
                priceAmount+=price;
            } 

            var taxAmount = parseFloat((priceAmount * 0.13).toFixed(2));
            var totalAmount = (priceAmount + taxAmount);
            setSubtotal(priceAmount.toFixed(2));
            setTax(taxAmount);
            setTotal(totalAmount.toFixed(2));

            let message = {
                height: document.body.scrollHeight,
                width: document.body.scrollWidth
              };
      
              // window.top refers to parent window
              window.top.postMessage(message, "*");
        }
        


    }, [order]);

    return (
        <div className="App Order-Page">
            <Helmet>
                <style>
                {`
                    :root {
                    --background-color: ${backgroundColor};
                    --text-color: ${textColor};
                    --secondary-text-color: ${secondaryTextColor};
                    --accent-color: ${accentColor};

                    }
                `}
                    </style>

            </Helmet>
            <div className="Checkout">
               <h1>Order Completed!</h1>
                
                <div className="Checkout-Wrapper" style={{borderTop: '0.5px solid #D2D2D2', paddingTop:30, justifyContent:'space-evenly', display:'flex', flexDirection:'row'}}>
                    {order.length > 0 ? (
                      <div>
                        <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                <img style={{width:90}}src={retailerLogo}></img>
                                <div style={{marginLeft:15}}>
                                    <h5 style={{margin:0}}>{retailer}</h5>
                                    <p style={{margin:0, fontWeight:'normal'}}>Est pick up: 35 - 45 min</p>
                                </div>
                            </div>

                            {failed === true ? (
                                <p style={{color:"#FF7F7F", fontSize:16, marginTop:15}}>Hi {name}, your order has been cancelled, if you think this is a mistake please try again or contact the store for support.</p>
                            ) : 
                                <>
                                    <p style={{fontSize:16, marginTop:15}}>Hi {name}, thank you for placing an order at {retailer}!</p>
                                    <p style={{fontSize:16, marginTop:-15}}>You will receive a text once your order is ready for pick up.</p>
                                </>
                            }
                            <p style={{fontSize:16, marginTop:5, marginBottom:30}}>{message}</p>


                            <label>Your Order Summary</label>
                            {
                               products.length > 0 ? (
                                    <>
                                        {products.map((product, i) => (

                                            <div style={{paddingTop:8, paddingBottom:8, paddingLeft:15, paddingRight:15, borderRadius:5, background: backgroundColor, border: "1px solid #D6D6D6", marginTop:15, alignItems:'center',  display:'flex', flexDirection:'row'}}>
                                                <img style={{width:40, height:40, marginRight:15}} src={product.Image}></img>
                                                <div style={{maxWidth:225}}>
                                                    <h5 style={{color: secondaryTextColor,fontSize:12, fontWeight:600, marginTop:0, marginBottom:0}}>{product.brandName}</h5>
                                                    <p style={{color: textColor, fontSize: 12, margin:0}}>{product.Name}</p>
                                                </div>
                                                <div style={{marginLeft: 'auto'}}>
                                                    <span style={{fontSize:12}} className="Checkout-Summary-Quantity">{product.ATCQUANTITY} x ${product.Prices[0].toFixed(2)}</span>
                                                </div>
                                            </div>
                                        ))}
                                    
                                    
                                        <div style={{paddingTop:10, paddingBottom:8, paddingLeft:15, paddingRight:15, borderRadius:5, background: backgroundColor, border: "1px solid #D6D6D6", marginTop:15, alignItems:'center',  display:'flex', flexDirection:'row'}}>
                                            <div>
                                                <p style={{color: textColor, fontSize: 12, margin:0}}>Subtotal</p>
                                            </div>
                                            <div style={{marginLeft: 'auto'}}>
                                                <span style={{fontSize:12}} className="Checkout-Summary-Quantity">${subtotal}</span>
                                            </div>
                                        </div>


                                        <div style={{paddingTop:10, paddingBottom:8, paddingLeft:15, paddingRight:15, borderRadius:5, background: backgroundColor, border: "1px solid #D6D6D6", marginTop:15, alignItems:'center',  display:'flex', flexDirection:'row'}}>
                                                <div>
                                                    <p style={{color: textColor, fontSize: 12, margin:0}}>Tax</p>
                                                </div>
                                                <div style={{marginLeft: 'auto'}}>
                                                    <span style={{fontSize:12}} className="Checkout-Summary-Quantity">${tax}</span>
                                                </div>
                                        </div>



                                            
                                        <div style={{paddingTop:10, paddingBottom:8, paddingLeft:15, paddingRight:15, borderRadius:5, background: backgroundColor, border: "1px solid #D6D6D6", marginTop:15, alignItems:'center',  display:'flex', flexDirection:'row'}}>
                                                <div>
                                                    <p style={{color: textColor, fontSize: 12, margin:0}}>Total</p>
                                                </div>
                                                <div style={{marginLeft: 'auto'}}>
                                                    <span style={{fontSize:12}} className="Checkout-Summary-Quantity">${total}</span>
                                                </div>
                                        </div>
                                    </>
                                ) : null
                            }                             
                        </div>
                    ): null}


                </div>

          

            </div>

        <div className="Footer">
            <a href="https://heed.chat" target="_blank">Powered by <span style={{color:"#E17E76", fontWeight:"bold"}}>heed</span></a>
        </div>
        </div>
    )
}

export default Order;
