import React, { useEffect, useState, useRef } from "react";
import Header from "../Components/Header/Header";
import MenuNavigationHeader from "../Components/MenuNavigationHeader/MenuNavigationHeader"
import Cart from "../Components/Cart/Cart";
import happy from "../happy.png";
import BackIcon from "../Components/ProductPageHeader/BackIcon.png";
import { useNavigate } from 'react-router-dom';
import { resolveOnChange } from "antd/lib/input/Input";
import fruity from "../fruity.png";
import citrus from "../citrus.png";
import floral from "../herbal.png";
import pine from "../pine.png";
import Hour from "../Components/Hour/Hour";
import { useParams } from 'react-router-dom'
import {Helmet} from "react-helmet";

function Product({}) {
  const { retailerId } = useParams()
  const [hours, setHours] = useState({});
  const [location, setLocation] = useState({});

  const [showCategories, setShowCategories] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showCart, setShowCart] = useState(false);  
  const [productOpen, setProductOpen] = useState();
  const [imageClass, setImageClass] = useState("Desktop-Product");
  const [productFlavours, setProductFlavours] = useState([]);
  const [recos, setRecos] = useState([]);
  const [backgroundColor, setBackgroundColor] = useState("#ffffff");
  const [textColor, setTextColor] = useState("#323232");
  const [secondaryTextColor, setSecondaryTextColor] = useState("#A5A5A5");
  const [accentColor, setAccentColor] = useState("#5096ff");

  const [amount, setAmount] = useState("1");
  const navigate = useNavigate();

  var flavours = {
    myrcene: "Fruity",
    limonene: "Fruity",
    "Para-cymene": "Fruity",
    citronellol: "Floral",
    geraniol: "Floral",
    linalool: "Floral",
    humulene: "Woody",
    "beta-caryophyllene": "Woody",
    terpinolene: "Woody",
    "alpha-pinene": "Piny",
    "beta-pinene": "Piny",
    "carene": "Piny"
  }

  // const hostname = "http://localhost:5000";
  const hostname = "https://heed.herokuapp.com";
  useEffect(() => {
    // IOS Parllax
   /* background-attachment:scroll !important; */
    var usrAgent = window.navigator.userAgent;
    if (usrAgent.match(/iPad|iPhone/i)) {
      // then do something
      setImageClass("Mobile-Product");
    }
    var productId = window.location.href.split("/product/")[1];
    // Fetch request
    fetch( `${hostname}/api/menu/${retailerId}/product/${productId}`, {    headers: { 'Content-Type': 'application/json' }, method: "GET", crossDomain:true })
    .then((response) => response.json())
    .then((response) => {
        if (response.success) {
            // Set Products
            if (response.data.length > 0) {
              var terpenes = [];
              for (var i = 0; i < response.data[0].terpenes.length; i++) {
                var terpene = response.data[0].terpenes[i];
                if (flavours[terpene.toLowerCase()] && !terpenes.includes(flavours[terpene.toLowerCase()])) {
                  console.log("flavour found");
                  terpenes.push(flavours[terpene.toLowerCase()]);
                }
              }
              setProductFlavours(terpenes);
              setProductOpen(response.data[0]);
              let message = {
                height: 1050,
                width: document.body.scrollWidth
              };
      
              // window.top refers to parent window
              window.top.postMessage(message, "*");
            }
        }
    })
    .catch((err) => {console.log(err)}); 



    fetch( `${hostname}/api/retailer/${retailerId}`, {    headers: { 'Content-Type': 'application/json' }, method: "GET", crossDomain:true })
    .then((response) => response.json())
    .then((response) => {
        if (response.success) {
            setLocation(response.data[0].location);
            setHours(response.data[0].hours);
            if (response.data[0].settings && response.data[0].settings.branding && response.data[0].settings.branding.backgroundColor) {
              setBackgroundColor(response.data[0].settings.branding.backgroundColor);
              setTextColor(response.data[0].settings.branding.textColor);
              setSecondaryTextColor(response.data[0].settings.branding.secondaryTextColor);
              setAccentColor(response.data[0].settings.branding.accentColor);
            }

            fetchRecos();
        }
    })
    .catch((err) => {console.log(err)}); 

  }, []);


  const fetchRecos = () => {

    // Pull products
    // Fetch request
    fetch( `${hostname}/api/menu/${retailerId}/products/upsell`, {    headers: { 'Content-Type': 'application/json' }, method: "GET", crossDomain:true })
    .then((response) => response.json())
    .then((response) => {
        if (response.success) {
            const shuffled = response.data.sort(() => 0.5 - Math.random());
            let selected = shuffled.slice(0, 5);
            setRecos(selected);
        }
    })
    .catch((err) => {console.log(err)});  
}


  // Add to cart function
  const addToCart = () => {
    var cartObj = JSON.parse(localStorage.getItem('heed-cart')) ? JSON.parse(localStorage.getItem('heed-cart')) : {};

    if (!cartObj[productOpen.id]) {
      cartObj[productOpen.id] = [];
    }
    
    for (var i = 0; i < parseInt(amount); i++) {
      cartObj[productOpen.id].push(productOpen);
    }

    var cartWeight = 0;

    Object.values(cartObj).map((productArr, i) => {
        var product = productArr[0];
        var productAmount = productArr.length;
        var netWeight = parseFloat(product.measurements)* productAmount;
        cartWeight+=netWeight;
    });

    // Calculate updated cart weight
    if (cartWeight > 30) {
      alert("Sorry! You've reached the 30g purchase limit for flower due to federal regulations.")
    } else {
      console.log('ATC weight is', cartWeight);
      localStorage.setItem('heed-cart', JSON.stringify(cartObj));
      setShowCart(true);
    }

  }




  

  return (
    <div className="App Product-Page">
           <Helmet>
            <style>
            {`
                  :root {
                  --background-color: ${backgroundColor};
                  --text-color: ${textColor};
                  --secondary-text-color: ${secondaryTextColor};
                  --accent-color: ${accentColor};

                  }
            `}
            </style>

          </Helmet>
        <div className="Product-Page-Wrapper">
        {!showCategories && !showInfo && Object.keys(hours).length !== 0 ? (
            <Header secondaryTextColor={secondaryTextColor} retailerId={retailerId} hours={hours} showCart={showCart} setShowCart={setShowCart} showCategories={showCategories} setShowCategories={setShowCategories} showInfo={showInfo} setShowInfo={setShowInfo} />
          ) : null }

          <Cart textColor={textColor} retailerId={retailerId} showCart={showCart} setShowCart={setShowCart}/>

          {
            showCategories || showInfo && Object.keys(hours).length !== 0 ? (
            <div className="Menu-Navigation">
              <MenuNavigationHeader hours={hours} showCategories={showCategories} setShowCategories={setShowCategories} showInfo={showInfo} setShowInfo={setShowInfo}/>
              <div className="Menu-Navigation-Body">
                
                <div className="Menu-Navigation-Pages">
                  <div onClick={() => {setShowInfo(false);setShowCategories(true)}}>
                    <span className={showCategories ? "active" : ""}>Categories</span>
                  </div>
                  <div onClick={() => {setShowCategories(false);setShowInfo(true);}}>
                    <span className={showInfo ? "active" : ""}>Info</span>
                  </div>
                </div>
  
                {showCategories ? (
                  <div className="Menu-Navigation-Categories">
                    <ul>
                      <li onClick={() => {window.location.href = `/${retailerId}`}} style={{color: accentColor}}>Home</li>
                      <li onClick={() => {window.location.href = `/${retailerId}/products/flower`}} style={{color: secondaryTextColor}}>Flower</li>
                      <li onClick={() => {window.location.href = `/${retailerId}/products/prerolls`}} style={{color: secondaryTextColor}}>PreRolls</li>
                      <li onClick={() => {window.location.href = `/${retailerId}/products/edible`}} style={{color: secondaryTextColor}}>Edibles</li>
                      <li onClick={() => {window.location.href = `/${retailerId}/products/concentrate`}} style={{color: secondaryTextColor}}>Concentrate</li>
                      <li onClick={() => {window.location.href = `/${retailerId}/products/vaporizers`}} style={{color: secondaryTextColor}}>Vaporizers</li>
                      <li onClick={() => {window.location.href = `/${retailerId}/products/topicals`}} style={{color: secondaryTextColor}}>Topicals</li>
                      <li onClick={() => {window.location.href = `/${retailerId}/products/brands`}} style={{color: secondaryTextColor}}>Brands</li>
                      <li onClick={() => {window.location.href = `/${retailerId}/products/accessories`}} style={{color: secondaryTextColor}}>Accessories</li>



                    </ul>
                  </div>  
                ) : (
                  <div style={{maxWidth:970, margin:'auto'}}>
                    <div style={{marginTop:30, width:"100%"}}>
                      <iframe width="100%" height="400" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src={`https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=${location.address}, ${location.city}, ${location.state}&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed`}>
                      </iframe>
                    </div>
  
                    <div className="Menu-Navigation-Info">                  
                    <div className="Info-Box">
                        <h5>Hours</h5>
  
                        <div style={{marginTop:0,display: 'flex', flexDirection:'row'}}>
                          <div style={{flexDirection:'column',marginRight:30}}>
                          <h6>Day</h6>
                          <ul className="list-group">
                            <li>Sunday</li>
                            <li>Monday</li>
                            <li>Tuesday</li>
                            <li>Wednesday</li>
                            <li>Thursday</li>
                            <li>Friday</li>
                            <li>Saturday</li>
                          </ul>
                          </div>
  
                          <div style={{flexDirection:'column'}}>
                          <h6>Pickup</h6>
                          <ul className="list-group">
                            {Object.keys(hours).map((keyName, i) => (
                                 <li>  
                                 <Hour time={hours[keyName].open} />
                                 -
                                 <Hour time={hours[keyName].close} />
                               </li>
                            ))}
                          </ul>
                        </div>
                        </div>
                      </div>
  
                      <div className="Info-Box">
                        <h5>Location</h5>
                        <p>{location.address} {location.city}, {location.state}</p>
                        <button onClick={() => {window.open(`http://maps.google.com/?q=${location.address} ${location.city}, ${location.state}`, '_blank')}}>Get Directions</button>
                      </div>
  
                    </div>
  
  
  
                  </div>
                 
                )}
            
  
              </div>
  
            </div>
            ) : null
            }

          <div className="Product">

              
              {productOpen ? (
                <>
                

                <div style={{backgroundImage: `url(${productOpen.Image})`}} className="Product-Image">
                  <button onClick={() => {navigate(-1);}} className="Back-Button">
                    {"<"} Back
                  </button>
                </div>

                <img className="Desktop-Product-Image" src={productOpen.Image}></img>


                <div className="Product-Body">

                  <h5 className="Product-Brand">{productOpen.brandName}</h5>
                  <h1>{productOpen.Name}</h1>



                  
                  <span className="Product-Price">
                  {productOpen.OverridePrice ? (
                        <>
                            <span style={{marginRight:5, color: "red", textDecoration: "line-through"}}>
                                ${productOpen.Prices[0].toFixed(2)}
                            </span>
                            ${productOpen.OverridePrice.toFixed(2)}
                        </>
                    ) : 
                        <>
                            ${productOpen.Prices[0].toFixed(2)}
                        </>
                    }
                    </span>

            
             
                 
                  <div className="Product-Info">
                    
                    <select value={amount} onChange={(e) => {setAmount(e.target.value)}}>
                      {[1,2,3,4,5,6,7,8,9].map(number => {
                        return (
                          <>
                            {productOpen.inventory < number ? null : (
                              <option value={number}>{number}</option>
                            )}
                          </>
                        )
                      })}
                    </select>

                    <button onClick={() => {addToCart()}} >Add to Cart</button>
                  </div>

                  <p style={{fontSize:12, color: secondaryTextColor, marginTop:15}}>*sales tax will be calcuated at checkout.</p>
                  
                  <div className="Product-Info">
                    {
                      productOpen.Weight && productOpen.Weight.value ? (
                        <span className="Product-Size">{productOpen.Weight.value}{productOpen.Weight.measure}</span>
                      ) : null
                    }

                  </div>

                  <div className="Product-Info">
                      
                    {productOpen.strainType ? 
                       <div className="Product-Info-Box">
                          <span className="Product-Plant">Type: <span style={{fontWeight:700}}>{productOpen.strainType}</span></span>
                        </div> : "" }
                      
                        {productOpen.THCContent && productOpen.THCContent.range && productOpen.THCContent.range.length > 0 ? 
                       <div className="Product-Info-Box">
                          <span className="Product-Plant">
                            THC: <span style={{fontWeight:700}}>
                              
                                {
                                    productOpen.THCContent && productOpen.THCContent.range && productOpen.THCContent.range.length > 0 ? (
                                      productOpen.THCContent.unit === "MILLIGRAMS" ? (
                                            `${productOpen.THCContent.range[productOpen.THCContent.range.length-1]}mg`
                                        ) : (
                                            `${productOpen.THCContent.range[0]}% - ${productOpen.THCContent.range[productOpen.THCContent.range.length-1]}%`
                                        )
                                    ) : null
                                }
                              </span></span>
                        </div> : "" }

                        {productOpen.CBDContent && productOpen.CBDContent.range && productOpen.CBDContent.range.length > 0 ? 
                        <div className="Product-Info-Box">
                          <span className="Product-Plant">CBD: <span style={{fontWeight:700}}>
                              {
                                    productOpen.CBDContent && productOpen.CBDContent.range && productOpen.CBDContent.range.length > 0 ? (
                                      productOpen.CBDContent.unit === "MILLIGRAMS" ? (
                                            `${productOpen.CBDContent.range[productOpen.CBDContent.range.length-1]}mg`
                                        ) : (
                                            `${productOpen.CBDContent.range[0]}% - ${productOpen.CBDContent.range[productOpen.CBDContent.range.length-1]}%`
                                        )
                                    ) : null
                              }
                          </span></span>
                        </div> : "" }

                  </div>

                  <p style={{marginTop:15, fontSize: 12, color: secondaryTextColor, fontWeight:400}}>
                    {productOpen.description ? productOpen.description : ""}
                  </p>

                
                  
                    <>
                      {productFlavours.length > 0 ? (
                        
                        <div style={{marginTop:45}} className="Product-Effects">
                          <label>Terpenes</label>
                          <div style={{marginTop:15, display: 'flex', flexDirection:'row', flexWrap:'wrap'}}>
                            
                            {
                              productFlavours.map(flavour => {
                                return (
                                  <div className="Product-Effect">
                                      {
                                        flavour == "Woody" ? (
                                          <img src={pine}/>
                                        ) : null
                                      }

                                      {flavour == "Piny" ? (
                                        <img src={pine}/>
                                      ) : null
                                      }
                                      {
                                        flavour == "Floral" ? (
                                          <img src={floral}/>
                                        ) : null
                                      }

                                      {
                                        flavour == "Fruity" ? (
                                          <img src={citrus}/>
                                        ) : null
                                      }

                                      <span>{flavour}</span>

                                  </div>
                                )
 
                              })
                            }
                          </div>
                        </div>

                      ) : null}
                    </>

                    <div style={{marginTop:30}}>
                      {
                      productOpen.terpenes.length > 0 ? (
                        <>
                        {
                          productOpen.terpenes.map(terpene => {
                            return (
                              <p style={{marginTop:10, marginBottom:10, fontSize: 12, fontWeight:400, color: secondaryTextColor}}>
                                {terpene}
                              </p>
                            )
                          })
                        }
                        </>
                      ) : null
                    }
                    </div>

                              
                        <div style={{padding:0, marginTop:30}} className="Cart-Recos">
                        {recos.length > 0 ? (
                            <label style={{fontWeight:600, fontSize:14, color: secondaryTextColor}}>Other's also liked...</label>
                        ) : null}
                        <div className="Cart-Products">
                        
                        {recos.map((product) => {
                            return (
                                
                                <div style={{height:150, minWidth:270}} className="Cart-Product">
                                    <div style={{marginRight:15}}>
                                        <img src={product.Image}></img>
                                    </div>

                                    <div>


                                        <a className="Reco-Name"  href={`/product/${product.id}`} style={{}}>{product.Name}</a>
                                        <p style={{fontSize: 10}}>{product.brandName}</p>
                                        <h2 style={{fontSize:10}}>
                                        {product.OverridePrice ? (
                                            <>
                                                <span style={{marginRight:5, color: "red", textDecoration: "line-through"}}>
                                                    ${product.Prices[0].toFixed(2)}
                                                </span>
                                                ${product.OverridePrice.toFixed(2)}
                                            </>
                                        ) : 
                                            <>
                                                ${product.Prices[0].toFixed(2)}
                                            </>
                                        }
                                        </h2>
                                        <button onClick={() => {
                                          var cartObj = JSON.parse(localStorage.getItem('heed-cart')) ? JSON.parse(localStorage.getItem('heed-cart')) : {};
                                      
                                          if (!cartObj[product.id]) {
                                            cartObj[product.id] = [];
                                          }
                                          
                                          for (var i = 0; i < parseInt(amount); i++) {
                                            cartObj[product.id].push(product);
                                          }
                                      
                                          var cartWeight = 0;
                                      
                                          Object.values(cartObj).map((productArr, i) => {
                                              var product = productArr[0];
                                              var productAmount = productArr.length;
                                              var netWeight = parseFloat(product.measurements)* productAmount;
                                              cartWeight+=netWeight;
                                          });
                                      
                                          // Calculate updated cart weight
                                          if (cartWeight > 30) {
                                            alert("Sorry! You've reached the 30g purchase limit for flower due to federal regulations.")
                                          } else {
                                            console.log('ATC weight is', cartWeight);
                                            localStorage.setItem('heed-cart', JSON.stringify(cartObj));
                                            setShowCart(true);
                                          }
                                      
                                        }}>Add to Cart</button>

                                    </div>                            

                                </div>
                            )
                        })}
                        </div>
                    </div>
                  {/* {
                    productOpen.brand.description !== "" ? (
                      <div style={{marginTop:30}} className="Product-Description">
                      <label>About the brand</label>
                      <img style={{width: 150, marginTop:0,marginLeft:0, borderRadius:0, marginRight:0}} src={productOpen.brand.imageUrl}></img>
                      <p>{productOpen.brand.description}</p>
                    </div>
                    ) : null
                  } */}

                </div>
                </>
              ) : null }
          </div>



        </div>


      
    </div>
  );
}

export default Product;

