import React, { useEffect, useState, useRef } from "react";

// Components
import Header from "../Components/Header/Header";
import MenuNavigationHeader from "../Components/MenuNavigationHeader/MenuNavigationHeader";
import Cart from "../Components/Cart/Cart";
import ProductRow from "../Components/ProductRow/ProductRow";
import {Slider} from "antd"
import 'antd/dist/antd.css';
import caretRight from "../caretRight.svg";
import caretDown from "../caretDown.svg";
import Hour from "../Components/Hour/Hour";
import { useParams } from 'react-router-dom'
import {Helmet} from "react-helmet";
import Heed from "../Components/Heed/Heed";


function Products({}) {
    const { retailerId } = useParams()
    const [hours, setHours] = useState({});
    const [location, setLocation] = useState({});

    // Action states
    const [title, setTitle] = useState("");
    const [showCategories, setShowCategories] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [showCart, setShowCart] = useState(false);
    const [initialLoad, setInitialLoad] = useState(false);
    const [search, setSearch] = useState("");
    const [sort, setSort] = useState("default");
    const [mobileFilters, setMobileFilters] = useState(false);
    const [sorted, setSorted] = useState(false);

    // Filters
    const [category, setCategory] = useState("");
    const [subcategory, setSubCategory] = useState("all");
    const [brand, setBrand] = useState(["all"]);
    const [sativa, setSativa] = useState(true);
    const [weight, setWeight] = useState(0);
    const [terpene, setTerpene] = useState(["all"]);

    const [indica, setIndica] = useState(true);
    const [hybrid, setHybrid] = useState(true);
    const [blend, setBlend] = useState(true);
    const [highCBD, setHighCBD] = useState(true);
    const [thc, setTHC] = useState([0, 100]);
    const [cbd, setCBD] = useState([0 ,100]);


    // Settings and variables
    const [products, setProducts] = useState([]);
    // const hostname = "http://localhost:5000";
    const hostname = "https://heed.herokuapp.com";
    const [showSubCategories, setShowSubCategories] = useState(true);
    const [showBrands, setShowBrands] = useState(false);
    const [showTypes, setShowTypes] = useState(false);
    const [showPotency, setShowPotency] = useState(false);
    const [showTerpenes, setShowTerpenes] = useState(false);

    const [showWeights, setShowWeights] = useState(false);

    const [subcategories, setSubCategories] = useState([]);
    const [categories, setCategories] = useState(["Accessories", "Flower", "Pre-Rolls", "Edible", "Concentrate", "Vaporizers", "Topicals"])

    const [backgroundColor, setBackgroundColor] = useState("#ffffff");
    const [textColor, setTextColor] = useState("#323232");
    const [secondaryTextColor, setSecondaryTextColor] = useState("#A5A5A5");
    const [accentColor, setAccentColor] = useState("#5096ff");
    const [heedOpen, setHeedOpen] = useState(false);


    // "batteries": "Batteries",
    // "devices": "Devices",
    // "papers": "Papers",
    // "grinders": "Grinders",
    // "glassware": "Glassware",
    // "trays": "Trays",
    // "dab tools":"Dab Tools"

    const subcategoryList = {
        flower: [],
        prerolls:[
            "singles",
            "pre-roll packs",
            "infused pre-rolls"
        ],
        edible: [
            "chocolates",
            "gummies",
            "baked goods",
            "drinks",
            "capsules",
            "chews",
            "hard candy",
            "sublingual products",
            "savory snacks",
            "dissolvables",
            "lozenges",
        ],
        concentrate: [
            "live resin",
            "rosin",
            "kief",
            "applicators",
            "sauce",
            "diamonds",
        ],
        vaporizers: [
            "cartridges",
            "pods",
            "disposables"
        ],
        topicals: [
            "topical oils",
            "lotions",
            "roll-ons",
            "bath products"
        ]
    }
    const [filters, setFilters] = useState();
    const presetFilters =  {
        all: {
            categories: ["All"]
        },
        brands: {
            categories: ["All"]
        },
        accessories: {
            categories: ["Accessories"]
        },
        flower: {
            categories: ["Flower"]
        },
        prerolls: {
            categories: ["Pre-Rolls"]
        },
        edible: {
            categories: ["Edible"]
        },
        concentrate: {
            categories: ["Concentrate"]
        },
        vaporizers: {
            categories: ["Vaporizers"]
        },
        topicals: {
            categories: ["Topicals"]
        }
    }
    const [brands, setBrands] = useState([]);
    const [weights, setWeights] = useState([]);
    const [terpenes, setTerpenes] = useState([]);

    // Page load use effect
    useEffect(() => {
        var menuTitle = window.location.href.split("/products/")[1].toLowerCase();
        setTitle(menuTitle);
        // if (subcategoryList[menuTitle]) {
        //     setSubCategories(subcategoryList[menuTitle]);
        // }
        if (menuTitle === "brands") {
            setShowBrands(true);
        }

        fetch( `${hostname}/api/retailer/${retailerId}`, {    headers: { 'Content-Type': 'application/json' }, method: "GET", crossDomain:true })
        .then((response) => response.json())
        .then((response) => {
            if (response.success) {
                setLocation(response.data[0].location);
                setHours(response.data[0].hours);

                if (response.data[0].settings && response.data[0].settings.branding && response.data[0].settings.branding.backgroundColor) {
                    setBackgroundColor(response.data[0].settings.branding.backgroundColor);
                    setTextColor(response.data[0].settings.branding.textColor);
                    setSecondaryTextColor(response.data[0].settings.branding.secondaryTextColor);
                    setAccentColor(response.data[0].settings.branding.accentColor);
                }

                // Set configured filters
                var allFilters = {};
                for (var i = 0; i < response.data[0].catalogue.categories.length; i++) {
                    var values = response.data[0].catalogue.categories[i].values;
                    for (var j = 0; j < values.length; j++) {
                        allFilters[values[j].link] = values[j].filter;
                    }
                }

                // Find filter to use for page
                setFilters(allFilters);


            }
        })
        .catch((err) => {console.log(err)}); 

    }, []);


    useEffect(() => {
        if (title && filters) {
            // Filter from backend

            if (filters[title]) {
                // Set preset filters values
                var filter = filters[title];
                // Plant type
                if (filter.type) {
                    if (!filter.type.includes("Sativa")) {
                        setSativa(false);
                    }
                    if (!filter.type.includes("Indica")) {
                        setIndica(false);
                    }
                    if (!filter.type.includes("Hybrid")) {
                        setHybrid(false);
                    }
                    if (!filter.type.includes("Blend")) {
                        setBlend(false);
                    }
                    if (!filter.type.includes("High CBD")) {
                        setHighCBD(false);
                    }
                }
                // THC
                if (filter.thc) {
                    setTHC(filter.thc);
                }
                // CBD
                if (filter.cbd) {
                    setCBD(filter.cbd);
                }

                // Category
                if (filter.categories) {
                    setCategory(filter.categories[0]);
                } else {
                    if (title === "calm") {
                        // setSubCategories(subcategoryList["edible"]);
                        setCategory("Edible");
                    } else {
                        // setSubCategories(subcategoryList["flower"]);
                        setCategory("Flower");
                    }
                }


            } else {

            // Filters from preset list
            if (presetFilters[title]) {
                // Set preset filters values
                var filter = presetFilters[title];
                // Plant type
                if (filter.type) {
                    if (!filter.type.includes("Sativa")) {
                        setSativa(false);
                    }
                    if (!filter.type.includes("Indica")) {
                        setIndica(false);
                    }
                    if (!filter.type.includes("Hybrid")) {
                        setHybrid(false);
                    }
                    if (!filter.type.includes("Blend")) {
                        setBlend(false);
                    }
                    if (!filter.type.includes("High CBD")) {
                        setHighCBD(false);
                    }
                }
                // THC
                if (filter.thc) {
                    setTHC(filter.thc);
                }
                // CBD
                if (filter.cbd) {
                    setCBD(filter.cbd);
                }

                // Category
                if (filter.categories) {
                    setCategory(filter.categories[0]);
                } else {
                    if (title === "calm") {
                        // setSubCategories(subcategoryList["edible"]);
                        setCategory("Edible");
                    } else {
                        // setSubCategories(subcategoryList["flower"]);
                        setCategory("Flower");
                    }
                }
            } 
            else {
                    window.location.href = `/${retailerId}/products/flower`;
                }
            }
        }



    }, [filters]);    

    useEffect(() => {
        if (!initialLoad && category !== "") {
            console.log(category, "Filters set!");
            fetchProducts();
        }
    }, [category])

    // Subcategory change
    useEffect(() => {
        if (initialLoad) {
            fetchProducts();
        }
    }, [subcategory]);

    // Weight change
    useEffect(() => {
        if (initialLoad) {
            fetchProducts();
        }
    }, [weight]);

    // Brand change
    useEffect(() => {
        if (initialLoad) {
            fetchProducts();
        }
    }, [brand]);

    // Plant types change
    useEffect(() => {
        if (initialLoad) {
            fetchProducts();
        }
    }, [sativa]);

    useEffect(() => {
        if (initialLoad) {
            fetchProducts();
        }
    }, [indica]);

    useEffect(() => {
        if (initialLoad) {
            fetchProducts();
        }
    }, [hybrid]);

    useEffect(() => {
        if (initialLoad) {
            fetchProducts();
        }
    }, [highCBD]);


    // THC change
    useEffect(() => {
        if (initialLoad) {
            fetchProducts();
        }
    }, [thc]);

    // CBD change
    useEffect(() => {
        if (initialLoad) {
            fetchProducts();
        }
    }, [cbd]);


    useEffect(() => {
        if (initialLoad) {
            fetchProducts();
        }
    }, [terpene]);


    // Sort the products list
    useEffect(() => {
        if (sort == "brand") {
            var sorted = products.sort(function(a, b) {
                var textA = a.brandName.toUpperCase();
                var textB = b.brandName.toUpperCase();
                if(textA === "" || textA === null) return 1;
                if(textB === "" || textB === null) return -1;
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            setSorted(true);
            setProducts(sorted);
        }
        if (sort == "default") {
            var sorted = products.sort(function(a, b) {
                var textA = a.Name.toUpperCase();
                var textB = b.Name.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            setSorted(true);
            setProducts(sorted);
        }

        if (sort == "price-low") {
            var sorted = products.sort(function(a, b) {
                var textA = !a.OverridePrice ? a.Prices[0]: a.OverridePrice;
                var textB = !b.OverridePrice ? b.Prices[0]: b.OverridePrice;
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            setSorted(true);
            setProducts(sorted);
        }

        if (sort == "price-high") {
            var sorted = products.sort(function(a, b) {
                var textA = a.Prices[0];
                var textB = b.Prices[0];
                return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
            });
            setSorted(true);
            setProducts(sorted);
        }

        if (sort == "potency") {
            var sorted = products.sort(function(a, b) {

                var textA = a.THCContent.range ? a.THCContent.range[1] : 0;
                var textB = b.THCContent.range ? b.THCContent.range[1] : 0;
                return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
            });
            setSorted(true);
            setProducts(sorted);
        }

    }, [sort]);

    useEffect(() => {
        if (sorted) {
            setSorted(false);
        }
    }, [sorted]);


    // Build filter object
    const buildFilter = () => {
        var filter = {
            categories: [category == "Accessories" || category == "accessories" ? "" : category]
        };


        // Subcategories
        if (subcategory !== "all") {
            filter.subcategories = [subcategory];
        }
      
        // Brands
        if (brand[0] !== "all") {
            filter.brands = brand;
        }

        // Weight
        if (weight) {
            filter.weight = parseFloat(weight);
        }


            
        // Terpene
        if (terpene[0] !== "all") {
            filter.terpenes = terpene
        }

        // Plant type
        // Sativa / Indica / Hybrid / High CBD
        if (sativa || indica || hybrid || highCBD || blend) {
            filter.type = [];
            if (sativa) {
                filter.type.push("Sativa");
            }
            if (indica) {
                filter.type.push("Indica");
            }
            if (hybrid) {
                filter.type.push("Hybrid");
            }
            if (highCBD) {
                filter.type.push("High CBD");
            }
            if (blend) {
                filter.type.push("Blend");
            }
        }
      
        // THC
        if (thc[0] === 0 && thc[1] === 100) {
        } else {
            filter.thc = thc;
        }
      
        // CBD
        if (cbd[0] === 0 && cbd[1] === 100) {
        } else {
            filter.cbd = cbd;
        }
        return filter;
    }

    // Fetch products function
    const fetchProducts = async () => {
        // Build filters
        var filter = buildFilter();
        // Fetch request
        
        fetch( `${hostname}/api/menu/${retailerId}/products`, {    headers: { 'Content-Type': 'application/json' }, method: "POST", body: JSON.stringify({filters: filter}), crossDomain:true })
        .then((response) => response.json())
        .then((response) => {
            if (response.success) {
                // Set Products
                if (brand[0] === "all") {
                    var availableBrands = response.data.map((productData) => productData.brandName);                
                    var availableCategories = response.data.map((productData) => productData.subcategory);                
                    availableBrands.sort(function(a,b){
                        return a.localeCompare(b);
                    });
                    availableCategories.sort(function(a,b) {
                        return a.localeCompare(b);
                    });

                    var finalBrands = [...new Set(availableBrands)];    
                    var finalCategories = [...new Set(availableCategories)];
                    if (!initialLoad) {
                        setSubCategories(finalCategories);
                    }
                    setBrands(finalBrands);
                }

                if (!weight) {
                    var availableWeights = response.data.map(productData => productData.Weight.value);
                    availableWeights = [...new Set(availableWeights)];    
                    availableWeights.sort(function(a, b) {
                        return a - b;
                      });
                    setWeights(availableWeights);
                }

                if (terpene[0] === "all") {
                    var availableTerpenes = [];
                    for (var i = 0; i < response.data.length; i++) {
                        var product = response.data[i];
                        
                        for (var j = 0; j < product.terpenes.length; j++) {
                            var terpeneVal = product.terpenes[j].toUpperCase();
                            if (availableTerpenes.indexOf(terpeneVal) === -1) {
                                availableTerpenes.push(terpeneVal);
                            }
                        }
      
                    }
                    var finalTerpenes = [...new Set(availableTerpenes)];    
                    setTerpenes(finalTerpenes);
                }
                    // var availableWeights = response.data.map(productData => productData.Weight.value);
                    // availableWeights = [...new Set(availableWeights)];    
                    // availableWeights.sort(function(a, b) {
                    //     return a - b;
                    //   });
                    // setWeights(availableWeights);
                
                

                if (!initialLoad) {
                    setInitialLoad(true);
                }
                setProducts(response.data);
                
                setTimeout(() => {
                    if (title === "brands") {
                        setSort("brand")
                        var sorted = response.data.sort(function(a, b) {
                            var textA = a.brandName.toUpperCase();
                            var textB = b.brandName.toUpperCase();
                            if(textA === "" || textA === null) return 1;
                            if(textB === "" || textB === null) return -1;
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        });
                        setSorted(true);
                        setProducts(sorted);
                    }

                    let message = {
                        height: document.body.scrollHeight,
                        width: document.body.scrollWidth
                      };
              
                      // window.top refers to parent window
                      window.top.postMessage(message, "*");

                }, 1000);
                

              
            }
        })
        .catch((err) => {console.log(err)});  
    }


    return (
        <div className="App Products-Page">


            <Helmet>
            <style>
            {`
                :root {
                --background-color: ${backgroundColor};
                --text-color: ${textColor};
                --secondary-text-color: ${secondaryTextColor};
                --accent-color: ${accentColor};

                }
            `}
            </style>

            </Helmet>


      {!showCategories && !showInfo && Object.keys(hours).length !== 0 ? (
                <Header secondaryTextColor={secondaryTextColor} retailerId={retailerId} hours={hours} showCart={showCart} setShowCart={setShowCart} showCategories={showCategories} setShowCategories={setShowCategories} showInfo={showInfo} setShowInfo={setShowInfo} />
            ) : null }

        {
            showCategories || showInfo && Object.keys(hours).length !== 0 ? (
                <div className="Menu-Navigation">
                <MenuNavigationHeader hours={hours} showCategories={showCategories} setShowCategories={setShowCategories} showInfo={showInfo} setShowInfo={setShowInfo}/>
                <div className="Menu-Navigation-Body">
                
                <div className="Menu-Navigation-Pages">
                    <div onClick={() => {setShowInfo(false);setShowCategories(true)}}>
                    <span className={showCategories ? "active" : ""}>Categories</span>
                    </div>
                    <div onClick={() => {setShowCategories(false);setShowInfo(true);}}>
                    <span className={showInfo ? "active" : ""}>Info</span>
                    </div>
                </div>

                {showCategories ? (
                    <div className="Menu-Navigation-Categories">
                    <ul>
                        <li onClick={() => {window.location.href = `/${retailerId}`}} style={{color: accentColor}}>Home</li>
                        <li onClick={() => {window.location.href = `/${retailerId}/products/flower`}} style={{color: secondaryTextColor}}>Flower</li>
                        <li onClick={() => {window.location.href = `/${retailerId}/products/prerolls`}} style={{color: secondaryTextColor}}>PreRolls</li>
                        <li onClick={() => {window.location.href = `/${retailerId}/products/edible`}} style={{color: secondaryTextColor}}>Edibles</li>
                        <li onClick={() => {window.location.href = `/${retailerId}/products/concentrate`}} style={{color: secondaryTextColor}}>Concentrate</li>
                        <li onClick={() => {window.location.href = `/${retailerId}/products/vaporizers`}} style={{color: secondaryTextColor}}>Vaporizers</li>
                        <li onClick={() => {window.location.href = `/${retailerId}/products/topicals`}} style={{color: secondaryTextColor}}>Topicals</li>
                        <li onClick={() => {window.location.href = `/${retailerId}/products/brands`}} style={{color: secondaryTextColor}}>Brands</li>
                        <li onClick={() => {window.location.href = `/${retailerId}/products/accessories`}} style={{color: secondaryTextColor}}>Accessories</li>
                    </ul>
                    </div>

                ) : (
                    <div style={{maxWidth:970, margin:'auto'}}>
                    <div style={{marginTop:30, width:"100%"}}>
                        <iframe width="100%" height="400" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src={`https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=${location.address}, ${location.city}, ${location.state}&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed`}>
                        </iframe>
                    </div>

                    <div className="Menu-Navigation-Info">                  
                    <div className="Info-Box">
                        <h5>Hours</h5>

                        <div style={{marginTop:0,display: 'flex', flexDirection:'row'}}>
                            <div style={{flexDirection:'column',marginRight:30}}>
                            <h6>Day</h6>
                            <ul className="list-group">
                            <li>Sunday</li>
                            <li>Monday</li>
                            <li>Tuesday</li>
                            <li>Wednesday</li>
                            <li>Thursday</li>
                            <li>Friday</li>
                            <li>Saturday</li>
                            </ul>
                            </div>

                            <div style={{flexDirection:'column'}}>
                          <h6>Pickup</h6>
                          <ul className="list-group">
                            {Object.keys(hours).map((keyName, i) => (
                                 <li>  
                                 <Hour time={hours[keyName].open} />
                                 -
                                 <Hour time={hours[keyName].close} />
                               </li>
                            ))}
                          </ul>
                        </div>
                        </div>
                        
                        </div>

                        <div className="Info-Box">
                            <h5>Location</h5>
                            <p>{location.address} {location.city}, {location.state}</p>
                            <button onClick={() => {window.open(`http://maps.google.com/?q=${location.address} ${location.city}, ${location.state}`, '_blank')}}>Get Directions</button>
                        </div>

                    </div>

                </div>
            
            )}
        

            </div>

            </div>
            ) : null
            }


        <div style={{display: showCategories || showInfo ? "none": "flex"}} className="App-Body Products-Body">
            <Cart textColor={textColor} retailerId={retailerId} showCart={showCart} setShowCart={setShowCart}/>
            <div className="Products-Titlebar-Mobile" style={{marginBottom: 15, marginTop:-30, alignItems:'flex-end', flexDirection:'row'}}> 
                <h5 style={{textTransform: 'capitalize'}} className="Product-Stack-Title">{title}</h5>
            </div>
            <div className="Products-Titlebar-Mobile" style={{marginBottom: 15, marginTop:0, alignItems:'flex-end', flexDirection:'row'}}> 

                <input className="Products-Search" placeholder="Search" value={search} onChange={(e) => {setSearch(e.target.value)}}></input>
                <button onClick={() => {setMobileFilters(!mobileFilters)}} className="Mobile-Filter-Button" style={{marginLeft:'auto'}}>Filters</button>
                
                <select value={sort} onChange={(e) => {setSort(e.target.value)}} className="Sort-Button" style={{marginLeft:'auto'}}>
                    <option value="default">Default</option>
                    <option value="brand">Brand</option>
                    <option value="price-low">Price Low</option>
                    <option value="price-high">Price High</option>
                    <option value="potency">Potency</option>
                </select>

            </div>

            {/* Filters sidebar */}
            <div style={{display: mobileFilters ? "block" : "none"}} className="Products-Sidebar">
                

                 {/* SUBCATEGORY */}
                 <>
                    {
                        title !== "brands" && subcategories.length > 0? (
                            <div>
                                {/* Subcategories [multi] */}
                                <h5 style={{cursor:'pointer'}} onClick={() => {setShowSubCategories(!showSubCategories)}}>SUBCATEGORIES <img style={{width:12, height:14}} src={showSubCategories ? caretDown : caretRight}></img></h5>
                                <ul style={{display: showSubCategories ? "block" : "none"}}>
                                    {subcategories.map(value => {
                                        return (
                                            <>
                                                {
                                                    value == 0 ? null : (
                                                        <li onClick={() => {
                                                            if (subcategory === value) {
                                                                setSubCategory("all");
                                                            } else {
                                                                setSubCategory(value)
                                                            }
                                                        }} className={subcategory === value ? "active" : ""}>{value.charAt(0).toUpperCase() + value.slice(1)}</li>
                                                    )
                                                }
                                            </>
                                        )
                                    })}
                                
                                </ul>
                            </div>
                        ) : null
                    }
                </>



                {
                    title !== "brands" ? (
                        <>
                        {category === "All" ? (
                            <div>
                               {/* Categories [multi] */}
                               <h5>Categories</h5>
                               <ul>
                                   <li style={{cursor: 'none'}} className={category === "All" ? "active" : ""}>All</li>
                                   {categories.map(val => {
                                       return (
                                           
                                           <li onClick={() => {setInitialLoad(false); setTitle(val); setSubCategories(subcategoryList[val.toLowerCase().replace("-", "")]); setCategory(val)}} className={category === val ? "active" : ""}>{val}</li>
                                       )
                                   })}
                               </ul>
                            </div>
                           ) : 
                               null
                           }    
                        </>
                    ) : null
                }

                {/* Weights */}
                <>
                    {
                        title !== "brands" && category !== "Edible" && weights.length > 1 ? (
                            <div>
                                {/* Subcategories [multi] */}
                                <h5 style={{cursor:'pointer'}} onClick={() => {setShowWeights(!showWeights)}}>WEIGHT <img style={{width:12, height:14}} src={showWeights ? caretDown : caretRight}></img></h5>
                                <ul style={{display: showWeights ? "block" : "none"}}>
                                    {weights.map(value => {
                                        return (
                                            <>
                                                {
                                                    value == 0 ? null : (
                                                        <li onClick={() => {
                                                            if (weight == value)
                                                                setWeight(0)
                                                            else
                                                                setWeight(value)
                                                        }} className={weight === value ? "active" : ""}>{value}g</li>
                                                    )
                                                }
                                            </>
                                        )
                                    })}
                                
                                </ul>
                            </div>
                        ) : null
                    }
                </>



            

                
                <div>
                        {/* Brands [multi] */}
                        <h5 style={{cursor:'pointer'}} onClick={() => {setShowBrands(!showBrands)}}>BRANDS <img style={{width:12, height:14}} src={showBrands ? caretDown : caretRight}></img></h5>
                        <ul style={{display: showBrands ? "block" : "none"}}>
                            <li onClick={() => {setBrand(["all"])}} className={brand[0] == "all" ? "active" : ""}>All</li>
                            {brands.map(val => {
                                return (
                                    <li onClick={() => {
                                        setBrand([val]);
                                    }} className={brand.includes(val) ? "active" : ""}>{val}</li>
                                )
                            })}
                        </ul>
                </div>
              


                {
                    title !== "brands" ? (
                        <div>
                            {/* Types [multi] */}
                            <h5 style={{cursor:'pointer'}} onClick={() => {setShowTypes(!showTypes)}}>TYPES <img style={{width:12, height:14}} src={showTypes ? caretDown : caretRight}></img></h5>
                            <ul style={{display: showTypes ? "block" : "none"}}>
                                <li>
                                    <input style={{marginRight:5}} type="checkbox" checked={sativa} onChange={(e) => {setSativa(e.target.checked);}} />
                                    Sativa
                                </li>
                                <li>
                                <input style={{marginRight:5}} type="checkbox" checked={hybrid} onChange={(e) => {setHybrid(e.target.checked);}} />
                                    Hybrid</li>
                                <li>
                                <input style={{marginRight:5}} type="checkbox" checked={indica} onChange={(e) => {setIndica(e.target.checked);}} />
                                    Indica</li>
                                    <li>
                                <input style={{marginRight:5}} type="checkbox" checked={blend} onChange={(e) => {setBlend(e.target.checked);}} />
                                    Blend</li>
                                <li>
                                <input style={{marginRight:5}} type="checkbox" checked={highCBD} onChange={(e) => {setHighCBD(e.target.checked);}} />
                                    High CBD</li>
                            </ul>
                        </div>
                    ) : null
                }


                {
                    title !== "brands" ? (
                        <div>
                            <h5 style={{cursor:'pointer'}} onClick={() => {setShowPotency(!showPotency)}}>POTENCY <img style={{width:12, height:14}} src={showPotency ? caretDown : caretRight}></img></h5>
                            <ul style={{display: showPotency ? "block" : "none"}}>
                                {/* THC [slider] */}
                                <div style={{marginTop:-15}}>
                                    <h5>THC: {thc[0]}% - {thc[1]}%</h5>
                            
                                    <Slider onChange={(value) => { setTHC(value) }} className="RangeSlider" disabled={false} range value={thc}  />                   
                                </div>
        
                                {/* CBD [slider] */}
                                <div>
                                    <h5>CBD: {cbd[0]}% - {cbd[1]}%</h5>
                                    <Slider onChange={(value) => { setCBD(value) }} className="RangeSlider"  disabled={false} range value={cbd}  />                   
        
                                </div>
                            </ul>
                        </div>
                    ) : null
                }


                {
                    title !== "brands" && terpenes.length > 0 ? (
                        <div>
                            <h5 style={{cursor:'pointer'}} onClick={() => {setShowTerpenes(!showTerpenes)}}>TERPENES <img style={{width:12, height:14}} src={showTerpenes ? caretDown : caretRight}></img></h5>
                            <ul style={{display: showTerpenes ? "block" : "none"}}>
                                <li onClick={() => {setTerpene(["all"])}} className={terpene[0] == "all" ? "active" : ""}>All</li>
                                {terpenes.map(val => {
                                    return (
                                        <li onClick={() => {
                                            setTerpene([val]);
                                        }} className={terpene.includes(val) ? "active" : ""}>{val}</li>
                                    )
                                })}                             
                            </ul>

                        </div>
                    ) : null
                }


            </div>



            <div style={{width:'100%'}}>
                {/* Sort list */}
                <div className="Products-Titlebar-Desktop" style={{marginBottom: 15, alignItems:'flex-end', flexDirection:'row'}}>
                    <h5 style={{textTransform: 'capitalize'}} className="Product-Stack-Title">{title}</h5>
                    <input className="Products-Search" placeholder="Search" value={search} onChange={(e) => {setSearch(e.target.value)}}></input>
                    <select value={sort} onChange={(e) => {setSort(e.target.value)}} className="Sort-Button" style={{marginLeft:'auto'}}>
                        <option value="default">Default</option>
                        <option value="brand">Brand</option>
                        <option value="price-low">Price Low</option>
                        <option value="price-high">Price High</option>
                        <option value="potency">Potency</option>
                    </select>
                    <button className="Mobile-Filter-Button" style={{marginLeft:'auto'}}>Filters</button>
                </div>

                {/* Product list view */}

                {
                    !sorted ? (
                        <>
                        {products.map(product => {
                            return (
                                <>
                                    {
                                        search === "" ? (
                                            <ProductRow retailerId={retailerId} setShowCart={setShowCart} product={product}></ProductRow>
                                        ) : (
                                            <>
                                                {product.Name.toLowerCase().includes(search.toLowerCase()) || product.brandName.toLowerCase().includes(search.toLowerCase()) ? (
                                                <ProductRow retailerId={retailerId} setShowCart={setShowCart} product={product}></ProductRow>
                                            ) : null}
                                            </>
                                        )
                                    }
                                </>
                            )
                        })}
                        </>
                    ) : null
                }
               
            </div>

            

        </div>
        {/* <Heed heedOpen={heedOpen} setHeedOpen={setHeedOpen} setShowCart={setShowCart} retailerId={retailerId}></Heed> */}


      <div className="Footer">
        <a href="https://heed.chat" target="_blank">Powered by <span style={{color:"#E17E76", fontWeight:"bold"}}>heed</span></a>
      </div>
                    
        </div>
    )
}

export default Products;
